import React from "react";
import { Top, TopCarousel } from "../components";
import { SupportCard } from "../components/molecules/SupportCard";
import { ArticleList } from "../components/organisms/ArticleList";
import { BrowserCity } from "../components/organisms/BrowseCityList";
import { CalenderList } from "../components/organisms/CalenderList";
import { EcoSystem } from "../components/organisms/EcoSystem";
import { LinkCardList } from "../components/organisms/LinkList";
import { NoticeCarousel } from "../components/organisms/NoticeCarousel";
import {
  FetchTag,
  GetArticleList,
  GetFeaturedArticle,
} from "../service/article";
import { CityList, GetCityInformation } from "../service/city";
import { GetTopEventsList } from "../service/event";
import { GetLinkList } from "../service/link";
import { GetNewsList } from "../service/news";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import Head from "next/head";
import { useTranslation } from "next-i18next";

export default function Home(props) {
  const { t } = useTranslation("common");

  return (
    <>
      <Head>
        <title>
          {t(`kansai startup ecosystem portal site - Deep tech Valley KANSAI`)}
        </title>
        <meta
          key="landing"
          name="description"
          content={t(
            "This is a portal site to introduce the attractiveness, potential, and future of the Kansai startup ecosystem to people in Japan and abroad. The site introduces Kansai startup events and various types of support. Osaka/Kyoto/Hyogo/Sakai/Kobe/Wakayama/Shiga/Tokushima/Tottori"
          )}
        />
        <meta
          name="keywords"
          content={t(
            "kansai startup,japan kansai startup ecosystem,kansai-ecosystem,deeptech kansai,Deep tech Valley KANSAI"
          )}
        />
      </Head>
      <Top />

      <TopCarousel initialData={props?.news} />
      <NoticeCarousel initialData={props?.featuredArticles} />
      <CalenderList initialData={props?.events} />

      <ArticleList
        initialData={props?.articles}
        initialTags={props?.initialTags}
      />

      <SupportCard />

      <EcoSystem />

      <BrowserCity
        initialCityLists={props?.cityLists}
        initialCityInfos={props?.cityInfos}
      />

      <LinkCardList initialData={props?.links} />
    </>
  );
}

export async function getServerSideProps(context) {
  const articles = await GetArticleList({
    pageParam: {},
    queryKey: { 0: "getArticleList", 1: "0", 2: "1", 3: "12" },
  });

  const news = await GetNewsList({
    pageParam: [],
    queryKey: {
      0: "",
      1: null,
      2: context?.locale,
    },
  });
  const featuredArticles = await GetFeaturedArticle({
    queryKey: {
      0: "featuredArticle",
      1: null,
    },
  });

  const events = await GetTopEventsList({
    queryKey: {
      0: "events",
      1: null,
    },
  });

  const cityLists = await CityList();
  const initialTags = await FetchTag({
    queryKey: {
      0: "",
      1: "",
    },
  });
  const cityInfos = await GetCityInformation();
  const links = await GetLinkList({
    pageParam: [],
    queryKey: {
      0: "",
      1: null,
    },
  });

  return {
    props: {
      ...(await serverSideTranslations(context?.locale, ["common"])),
      articles,
      news,
      featuredArticles,
      events,
      cityLists,
      cityInfos,
      links,
      initialTags,
    },
  };
}
